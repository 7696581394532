import React from "react"
import InlineBenefitsSection from "../../../Common/CommonSections/InlineBenefitsSection"
import { StyledMarginWrapper } from "../../common-styles"
import { BENEFIT_ITEMS, INLINE_BENEFITS_SECTION_TITLE } from "../../constants"

const InlineBenefits = () => {
  return (
    <StyledMarginWrapper>
      <InlineBenefitsSection
        title={INLINE_BENEFITS_SECTION_TITLE}
        configuration={BENEFIT_ITEMS}
      />
    </StyledMarginWrapper>
  )
}

export default InlineBenefits;
